<template>
  <cms-page
    v-if="page && (!personId || record)"
    :value="page"
    :data="pageData"
    @update:data="onUpdateData"
    @page-event="$emit('page-event', $event)"
    @update:errors="$emit('update:errors', $event)"
  ></cms-page>
</template>

<script>
import useApi from '@/modules/api/mixins/useApi.js';
import apiCms from '@/modules/cms/api/index.js';

import CmsPage from '@/modules/cms/components/Page/Page.vue';

export default {
  name: 'cms-api-page',
  mixins: [useApi],
  $api: apiCms,

  components: {
    CmsPage,
  },

  props: {
    pageId: {
      type: String,
      required: true,
    },

    data: {
      type: Object,
      required: false,
      default: () => ({}),
    },

    personId: {
      type: String,
      required: false,
      default: null,
    },

    // ID de la "tabla" en la que se guarda el registro de datos.
    // si no se especifica, se usa el mismo valor de "pageId"
    tableId: {
      type: String,
      required: false,
      default: null,
    },
  },

  data() {
    return {
      page: null,
      record: null,
      saveTimeout: null,

      innerData: {},
    };
  },

  computed: {
    recordTable() {
      return this.tableId || this.pageId || 'records';
    },

    pageData() {
      return Object.assign(
        {},
        this.personId ? { person: { id: this.personId } } : null,
        this.innerData,
        this.data,
        { record: this.record }
      );
    },
  },

  watch: {
    pageId: {
      immediate: true,
      handler() {
        this.fetchRecord();
        this.fetchPage();
      },
    },
  },

  methods: {
    async fetchPage() {
      try {
        this.page = await this.$api.getPage(this.pageId);

        if(!this.page.blocks || !this.page.blocks.length) {
          throw "tablero vacio";
        }
        this.$emit('fetch', this.page);
      } catch (err) {
        this.page = null;
        throw err;
      }
    },

    async fetchRecord() {
      if (!this.personId) {
        return;
      }

      this.record = await this.$api.obtainRecord(
        this.recordTable,
        this.personId
      );
    },

    onUpdateData($event) {
      if (this.record) {
        this.record.data = Object.assign(
          {},
          $event.record ? $event.record.data : {}
        );
      }

      this.innerData = Object.assign({}, $event);

      this.debounceSaveRecord();
      this.$emit('update:data', $event);
    },

    debounceSaveRecord() {
      clearTimeout(this.saveTimeout);
      this.saveTimeout = setTimeout(this.saveRecord, 600);
    },

    async saveRecord() {
      if (!this.personId || !this.record || !this.record.id) {
        return;
      }

      clearTimeout(this.saveTimeout);
      await this.$api.updateRecord(
        this.recordTable,
        this.personId,
        this.record.data
      );
    },
  },
};
</script>