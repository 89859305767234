<template>
  <phi-page color="#f2f2f2">
    <div slot="toolbar">
      <mu-icon-button
        @click="$router.go(-1)"
        icon="arrow_back"
      />
      <h1>{{ group ? group.course.subject.name : '...' }}</h1>
      <academic-period-picker
        v-if="group && group.course.year"
        view="native"
        :year-id="group.course.year"
        v-model="periodId"
        :query="{person:personId}"
      ></academic-period-picker>
    </div>

    <v3-academic-course-group-dashboard
      class="ui-card"
      :person-id="personId"
      :group-id="groupId"
      :period-id="periodId"
      @fetch-group="onFetchGroup"
      @click-post="onClickPost"
    ></v3-academic-course-group-dashboard>
  </phi-page>
</template>

<script>
import AcademicPeriodPicker from '@/modules/v3/components/V3AcademicPeriodPicker/V3AcademicPeriodPicker.vue';
import V3AcademicCourseGroupDashboard from '@/modules/v3/components/V3AcademicCourseGroupDashboard/V3AcademicCourseGroupDashboard.vue';

export default {
  components: {
    AcademicPeriodPicker,
    V3AcademicCourseGroupDashboard,
  },

  data() {
    return {
      personId: this.$route.params.personId,
      groupId: this.$route.params.groupId,
      periodId: this.$route.query.period,

      group: null,
    };
  },

  computed: {
    nodeId() {
      return `GP${this.groupId}-${this.periodId}`;
    },
  },

  methods: {
    onFetchGroup(group) {
      this.group = group;
    },

    onClickPost(post) {
      this.$router.push(
        `/people/${this.personId}/classroom/${this.groupId}/posts/${post.id}`
      );
    },
  },
};
</script>

<style lang="scss">
.teacher-year-picker {
  border: 0;
  background: transparent;
}

.expanded-toolbar {
  z-index: 3;
}
</style>