<template>
  <div class="v3-academic-course-group-dashboard">
    <ui-tabs
      class="type-list"
      v-model="currentTab"
    >
      <ui-tab
        value="blackboard"
        text="Tablero"
        icon="mdi:google-classroom"
        v-show="mostrarTablero"
      ></ui-tab>

      <ui-tab
        value="grades"
        :text="$t(`Notas`)"
        icon="mdi:pencil"
      ></ui-tab>

      <ui-tab
        v-for="type in types"
        :key="type.id"
        :value="`type-${type.id}`"
        :text="type.plural"
        :icon="type.icon"
        :color="type.color"
      ></ui-tab>
    </ui-tabs>

    <div v-show="currentTab == 'blackboard'">
      <cms-api-page
        v-if="group && group.course && group.course.id"
        :page-id="`v3-acd-course-${group.course.id}-${periodId}`"
        @fetch="mostrarTablero=true; currentTab='blackboard'"
      ></cms-api-page>

      <cms-api-page :page-id="`v3-acd-group-${groupId}-${periodId}`"  @fetch="mostrarTablero=true; currentTab='blackboard'"></cms-api-page>
    </div>

    <div v-show="currentTab == 'grades'">
      <v3-academic-consolidate-student-course
        v-if="personId && periodId && group"
        :person-id="personId"
        :period-id="periodId"
        :course-id="group.course.id"
      ></v3-academic-consolidate-student-course>
    </div>

    <div class="post-list">
      <post-list
        :posts="filteredPosts"
        @click-post="$emit('click-post', $event)"
      ></post-list>
    </div>
  </div>
</template>

<script>
import useApi from '@/modules/api/mixins/useApi.js';
import apiCommunication from '@/modules/communication/api/index.js';
import apiAcademic from '@/modules/v3/api/academic.js';

import { UiItem, UiTabs, UiTab } from '@/modules/ui/components';

import CmsApiPage from '@/modules/cms/components/Api/Page.vue';
import V3AcademicConsolidateStudentCourse from '@/modules/v3/components/V3AcademicConsolidateStudentCourse/V3AcademicConsolidateStudentCourse.vue';
import PostList from '@/modules/communication/components/Post/PostList.vue';

export default {
  name: 'v3-academic-course-group-dashboard',
  mixins: [useApi],

  $api: {
    communication: apiCommunication,
    academic: apiAcademic,
  },

  components: {
    UiItem,
    UiTabs,
    UiTab,
    CmsApiPage,
    V3AcademicConsolidateStudentCourse,
    PostList,
  },

  props: {
    personId: {
      type: String,
      required: true,
    },

    groupId: {
      type: String,
      required: true,
    },

    periodId: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      group: null,
      posts: [],
      currentTab: 'grades',
      mostrarTablero: false
    };
  },

  computed: {
    nodeId() {
      return `GP${this.groupId}-${this.periodId}`;
    },

    types() {
      let retval = {};
      this.posts.forEach((post) => (retval[post.type.id] = post.type));
      return Object.values(retval);
    },

    filteredPosts() {
      return this.posts
        .filter((p) => 'type-' + p.type.id == this.currentTab)
        .sort((a, b) => b.datePublished - a.datePublished);
    },
  },

  watch: {
    groupId: {
      immediate: true,
      handler() {
        this.fetchGroup();
      },
    },

    nodeId: {
      immediate: true,
      handler() {
        this.fetchPosts();
      },
    },
  },

  methods: {
    async fetchGroup() {
      this.group = await this.$api.academic.getAcademicGroup(this.groupId);
      this.$emit('fetch-group', this.group);
    },

    async fetchPosts() {
      this.posts = await this.$api.communication.getNodePosts(
        this.nodeId,
        this.showDrafts ? { drafts: 1 } : null
      );
      this.$emit('fetched', this.posts);
    },
  },
};
</script>